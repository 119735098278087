import { sendMail } from '../api/domains/mail';
import { MAIL_TYPES } from '../constants';

export const sendInfoMail = async ({
    name, companyName, number, email, turnoverLess, turnoverAbove, question, memberInterest
}) => {
    const type = memberInterest ? 'Intresseanmälan' : 'Fråga';
    const turnover = turnoverLess ? 'Under omsättningsgräns' : turnoverAbove ? 'Över omsättningsgräns' : 'Ej angiven';

    const subject = `${type} från ${email ?? ''}`;

    const body = `
        <h2>${type} mottagen från ${email ?? ''}</h2>
        <p>${name ? 'Namn: ' + name : ''}</p>
        <p>${companyName ? 'Företagsnamn: ' + companyName : ''}</p>
        <p>${number ? 'Telefon: ' + number : ''}</p>
        <p>${email ? 'Email: ' + email : ''}</p>
        <br />
        <p>${question ? 'Fråga: ' + question : ''}</p>
        <br />
        <p>${memberInterest ? 'Omsättning: ' + turnover : ''}</p>
    `;

    return await sendMail(MAIL_TYPES.INFO, subject, body);
};

export const sendSupportMail = async ({
    subject, email, message
}) => {        
    const body = `
        <h2>Supportärende mottaget från ${email ?? ''}</h2>
        <p>Ärende: ${subject ?? ''}</p>
        <p>Beskrivning: ${message ?? ''}</p>
    `;

    return await sendMail(MAIL_TYPES.SUPPORT, subject, body);
};