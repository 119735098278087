import React, { useState, useContext } from "react";
import { TranslationContext } from '../../translation/translationContext';
import InputWithLabel from '../texts/inputWithLabel';
import StandardButton from '../buttons/standardButton';
import CheckBoxWithLabel from '../buttons/checkBoxWithLabel';
import { TURNOVER_LIMIT } from '../../constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 48
  },
  title: {
    alignSelf: 'center',
    color: '#5A96BE',
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: 40,
    marginBottom: 32    
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 17
  },
  inputWithLabel: {
    marginBottom: 24,
    width: '100%'
  },
  turnoverContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%'
  },
  checkBoxWithLabel: {

  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  bottomLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '40%',
  },
  textarea: {
    width: '50%',
    height: 300,
    padding: 16,
    border: '1px solid #d3d3d3',
    backgroundColor: 'rgba(196, 196, 196, 0.22)',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 17
  },
  sendButton: {
    alignSelf: 'flex-end',
    marginBottom: 120
  }
};

const Contacts = ({ style, onSend }) => {
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [turnoverLess, setTurnoverLess] = useState(false);
    const [turnoverAbove, setTurnoverAbove] = useState(false);
    const [question, setQuestion] = useState('');
    const [memberInterest, setMemberInterest] = useState(false);

    const { translate } = useContext(TranslationContext);

    const onCheckTurnOverLess = () => {
      setTurnoverLess(prev => !prev);
      setTurnoverAbove(false);
    };

    const onCheckTurnOverAbove = () => {
      setTurnoverAbove(prev => !prev);
      setTurnoverLess(false);
    };

    const onCheckMemberInterest = () => {
      setMemberInterest(prev => !prev);
    };

    const clear = () => {
      setName('');
      setCompanyName('');
      setNumber('');
      setEmail('');
      setTurnoverLess(false);
      setTurnoverAbove(false);
      setQuestion('');
      setMemberInterest(false);
    };

    const onSubmit = () => {
      const contactData = {
        name, companyName, number, email, turnoverLess, turnoverAbove, question, memberInterest
      };
      onSend(contactData);
      clear();
    };

    return (
        <div id="contacts" style={{...styles.container, ...style}}>
        <h2 style={styles.title}>{translate('contactTitle')}</h2>
        <InputWithLabel
          style={styles.inputWithLabel}
          label={`${translate('name')}:`}
          value={name}
          setValue={setName}
        />
        <InputWithLabel
          style={styles.inputWithLabel}
          label={`${translate('companyName')}:`}
          value={companyName}
          setValue={setCompanyName}
        />
        <InputWithLabel
          style={styles.inputWithLabel}
          label={`${translate('contactNumber')}:`}
          value={number}
          setValue={setNumber}
        />
        <InputWithLabel
          style={styles.inputWithLabel}
          label={`${translate('contactEmail')}:`}
          value={email}
          setValue={setEmail}
        />
        {memberInterest &&<div style={styles.turnoverContainer}>
            <p style={styles.text}>{`${translate('companyTurnover')}:`}</p>
            <CheckBoxWithLabel style={styles.checkBoxWithLabel} label={`${translate('upTo')} ${TURNOVER_LIMIT}`} isChecked={turnoverLess} onCheck={onCheckTurnOverLess} />
            <CheckBoxWithLabel style={styles.checkBoxWithLabel} label={`${translate('above')} ${TURNOVER_LIMIT}`} isChecked={turnoverAbove} onCheck={onCheckTurnOverAbove} />
        </div>}
        <div style={styles.bottomContainer}>
                <div style={styles.bottomLeftContainer}>
                    <p style={styles.text}>{`${translate('queryDescription')}:`}</p>
                    <CheckBoxWithLabel label={`${translate('memberInterested')}`} isChecked={memberInterest} onCheck={onCheckMemberInterest} />
                </div>
                <textarea
                  style={styles.textarea}
                  value={question}
                  onChange={event => setQuestion(event.target.value)}
                />
            </div>
        <StandardButton style={styles.sendButton} text={translate('sendLarge').toUpperCase()} onClick={onSubmit} />
        </div>
    );
};

  export default Contacts;