import React from 'react';
import CheckBox from './checkBox';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    checkbox: {
        marginRight: 16
    },
    label: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 17
    }
};

const CheckBoxWithLabel = ({ style, isChecked = false, onCheck, label }) => (
    <div style={{...styles.container, ...style}}>
        <CheckBox style={styles.checkbox} isChecked={isChecked} onCheck={onCheck} uncheckedColor='rgba(196, 196, 196, 0.22)'  />
        <p style={styles.label}>{label}</p>
    </div>
);

export default CheckBoxWithLabel;