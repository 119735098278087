import React, { useContext, useState } from "react";
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/outsideNavbar';
import { BLACK } from '../assets/colors';
import logo from '../assets/images/logo.svg';
import { outsideNavbarHeight } from '../assets/dimensions';
import Contacts from '../components/views/contacts';
import middleBackground from '../assets/images/middleBackground.png';
import bottomBackground from '../assets/images/bottomBackground.png';
import { sendInfoMail } from '../services/mail';
import SentDialog from '../components/dialogs/simpleDialog';

const styles = {
  container: {
    backgroundColor: 'white'
  },
  logo: {
    marginTop: outsideNavbarHeight + 48,   
  },
  slogan: {
    color: BLACK,
    fontFamily: 'PoiretOne-Regular',
    fontWeight: 400,
    fontSize: '25px',
    marginBottom: 60
  },
  backgroundImage: {
    width: '100%',
  },
};

const IndexPage = () => {
  const { translate } = useContext(TranslationContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const onSend = async (contactData) => {
    try {
      await sendInfoMail(contactData);
      setDialogOpen(true);
    } catch (e) {
      // TODO: Show error
    }
  };

  return (
    <Screen style={styles.container}>
      <Navbar currentTab={'/'} />
      <SentDialog
        message={translate('mailSent')}
        isOpen={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <img style={styles.logo} src={logo} alt="logo" />
      <h2 style={styles.slogan}>{translate('slogan')}</h2>
      <img style={styles.backgroundImage} src={middleBackground} alt="decoration" />
      <Contacts onSend={onSend} />
      <img style={styles.backgroundImage} src={bottomBackground} alt="decoration" />
    </Screen>
  )
}

export default IndexPage
