import React from 'react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        marginRight: 16,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 17
    },
    input: {
        width: '70%',
        border: '1px solid #d3d3d3',
        backgroundColor: 'rgba(196, 196, 196, 0.22)',
        fontSize: 17,
        fontWeight: 400,
        padding: 12
    }
};

const InputWithLabel = ({ style, label, type, placeholder = '', value, setValue = (() => {}) }) => (
    <div style={{...styles.container, ...style}}>
        <p style={styles.label}>{label}</p>
        <input
            style={styles.input}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={event => setValue(event.target.value)}
        />
    </div>
);

export default InputWithLabel;