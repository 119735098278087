import React from 'react';

const styles = {
    container: {
        width: 35,
        height: 35,
        border: '1px solid #d3d3d3',
        display: 'flex',    
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerBox: {
        width: 25,
        height: 25
    }
};

const CheckBox = ({ style, isChecked, onCheck, uncheckedColor = 'white', checkedColor = 'black' }) => (
    <div style={{ ...styles.container, ...style, backgroundColor: uncheckedColor }} onClick={onCheck}>
        <div style={{...styles.innerBox, backgroundColor: isChecked ? checkedColor : uncheckedColor }} />
    </div>
);

export default CheckBox;